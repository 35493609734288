@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'SpaceGrotesk', sans-serif;
  }
}

body {
  @apply bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-white;
}

@keyframes dropdown-animation {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.z-50 {
  z-index: 50;
}

.animate-dropdown {
  animation: dropdown-animation 0.3s ease-in-out;
}

.loading-wave-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}

.loading-wave-container.dark {
  background-color: #1e1e1e;
}

.loading-wave {
  width: 300px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.loading-bar {
  width: 20px;
  height: 10px;
  margin: 0 5px;
  background-color: #ffe176;
  border-radius: 5px;
  animation: loading-wave-animation 1s ease-in-out infinite;
}

.loading-bar:nth-child(2) {
  animation-delay: 0.1s;
}

.loading-bar:nth-child(3) {
  animation-delay: 0.2s;
}

.loading-bar:nth-child(4) {
  animation-delay: 0.3s;
}

.sidebar-item {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  text-decoration: none;
  color: var(--text-color);
  transition: background-color 0.3s, transform 0.3s;
  border-radius: 10px;
  margin-bottom: 8px;
  margin-left: 12px;
  margin-right: 12px;
  transform: translateX(0);
}

.sidebar-item:hover {
  background-color: #4a4a4a;
  transform: translateX(10px);
}

.sidebar-item.selected {
  background-color: #e5c34b29;
}

.dark .sidebar-item {
  color: #e2e8f0;
}

.dark .sidebar-item:hover {
  background-color: #4a4a4a;
}

.dark .sidebar-item.selected {
  background-color: #e5c34b29;
}

/* Updated styles for collapsible sidebar */
.sidebar-item.collapsed {
  justify-content: center;
  width: 3.5rem;
  height: 3.5rem;
  padding: 0;
  margin: 8px auto;
}

.sidebar-item.collapsed.selected {
  border-radius: 10px;
}

.sidebar-toggle-button {
  position: absolute;
  top: 50%;
  right: -12px;
  transform: translateY(-50%);
  background-color: #ffffff;
  border-radius: 50%;
  padding: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s;
  color: var(--text-color);
}

.dark .sidebar-toggle-button {
  background-color: #4a4a4a;
  color: #e2e8f0;
}

.sidebar-toggle-button:hover {
  background-color: #f0f0f0;
}

.dark .sidebar-toggle-button:hover {
  background-color: #606060;
}

@keyframes loading-wave-animation {
  0% {
    height: 10px;
  }
  50% {
    height: 50px;
  }
  100% {
    height: 10px;
  }
}
