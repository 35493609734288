body {
  margin: 0;
  font-family: 'SpaceGrotesk';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: 'AppleGaramond';
  src: url('/public/fonts/AppleGaramond.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SpaceGrotesk';
  src: url('/public/fonts/SpaceGrotesk-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'AppleGaramond';
  src: url('/public/fonts/AppleGaramond.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'AppleGaramond';
  src: url('/public/fonts/AppleGaramond-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Urbanist';
  src: url('/public/fonts/Urbanist-VariableFont_wght.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
